import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const tvasSlice = createSlice({
    name: 'tvas',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {

        resetdetailsTvas: (state) => {
            if (state.detailsTvas == null) {
                state.detailsTvas = ''
            } else {
                state.detailsTvas = null
            }
        },

        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },

        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsTvas.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsTvas.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsTvas = action.payload
            })
            .addCase(fetchDetailsTvas.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchTvas.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchTvas.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchTvas.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createTva.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createTva.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createTva.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteTva.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteTva.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteTva.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchTvas = createAsyncThunk(
    'tvas/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/compta.tva.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch tvas')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsTvas = createAsyncThunk(
    'Tvas/fetchDetails',
    async (id) => {
        const data = { 'id': id.id }
        try {
            const Tva = await BaseService.post(
                `/XML/compta.tva.load/side_details_tva`, data
            )
            const journal = await BaseService.post(
                `/XML/compta.jr.load/get_journal_by_lbl`, { 'lbl': 'TVA_' + Tva.data.date }
            )
            ////////////////////////////formatting the tva data
            const tvaVente = []
            const tvaCo = []
            const tvaImmo = []
            let arrkeys = ['CATotalHt', 'CA20', 'CA14','CA13', 'CA10', 'CA7', 'CA0']
            let arr = ['CA Total Ht', 'CA 20%', 'CA 14%','CA 13%', 'CA 10%', 'CA 7%', 'CA 0%']

            for (let index = 0; index < arrkeys.length; index++) {
                const key = arrkeys[index];
                const lbl = arr[index];
                tvaVente.push({ 'lbl': lbl, 'value': Tva.data.factures.objetDeclaration[key] })
            }
            arrkeys = ['20', '14','13', '10', '7', '0']
            arr = ['Achats 20%', 'Achats 14%','Achats 13%', 'Achats 10%', 'Achats 7%', 'Achats 0%']

            for (let index = 0; index < arrkeys.length; index++) {


                let key = arr[index] + " à l'interieur"
                let soldeFacture = Tva.data.factures.objetDeclaration.achats.co.interieur[arrkeys[index]].surFacture
                let soldeDeduir = Tva.data.factures.objetDeclaration.achats.co.interieur[arrkeys[index]].deductible
                tvaCo.push({ 'lbl': key, 'value': soldeFacture, 'deductible': soldeDeduir })
                /////////////////////////////////
                key = arr[index] + " à l'import"
                soldeFacture = Tva.data.factures.objetDeclaration.achats.co.import[arrkeys[index]].surFacture
                soldeDeduir = Tva.data.factures.objetDeclaration.achats.co.import[arrkeys[index]].deductible
                tvaCo.push({ 'lbl': key, 'value': soldeFacture, 'deductible': soldeDeduir })

                /////////////////////////////////
                key = arr[index] + " à l'interieur"
                soldeFacture = Tva.data.factures.objetDeclaration.achats.immo.interieur[arrkeys[index]].surFacture
                soldeDeduir = Tva.data.factures.objetDeclaration.achats.immo.interieur[arrkeys[index]].deductible
                tvaImmo.push({ 'lbl': key, 'value': soldeFacture, 'deductible': soldeDeduir })
                /////////////////////////////////
                key = arr[index] + " à l'import"
                soldeFacture = Tva.data.factures.objetDeclaration.achats.immo.import[arrkeys[index]].surFacture
                soldeDeduir = Tva.data.factures.objetDeclaration.achats.immo.import[arrkeys[index]].deductible
                tvaImmo.push({ 'lbl': key, 'value': soldeFacture, 'deductible': soldeDeduir })


            }
            /////////////////////formatting the facture data
            const arrDoitFr = []
            const arrAvoirFr = []
            const arrDoitCl = []
            const arrAvoirCl = []
            // ------------------formating facture fournisseur doit----------------
            Object.keys(Tva.data.factures.doit_fournisseur).forEach(key => {
                const element = Tva.data.factures.doit_fournisseur[key];

                const ttc = element.ttc.objttc
                const ht_tva = ttc.ht_tva
                const tva = ttc.tva
                Object.keys(ht_tva).forEach(taux => {
                    const ht = ht_tva[taux]
                    const mt_tva = tva[taux]
                    const taux_tva = taux.substring(3)
                    const ttc_facture = Math.round((Number(mt_tva) + Number(ht)) * 100) / 100

                    arrDoitFr.push({ 'num': element.facture.num_format, 'totalht': ht, 'totalttc': ttc_facture, 'tauxtva': taux_tva, 'tva': mt_tva, 'prorata': element.prorata })

                })

            })
            // ------------------formating facture fournisseur avoir----------------
            Object.keys(Tva.data.factures.avoir_fr).forEach(key => {
                const element = Tva.data.factures.avoir_fr[key];

                const ttc = element.ttc.objttc
                const ht_tva = ttc.ht_tva
                const tva = ttc.tva
                Object.keys(ht_tva).forEach(taux => {
                    const ht = ht_tva[taux]
                    const mt_tva = tva[taux]
                    const taux_tva = taux.substring(3)
                    const ttc_facture = Math.round((Number(mt_tva) + Number(ht)) * 100) / 100
                    arrAvoirFr.push({ 'num': element.facture.num_format, 'totalht': ht, 'totalttc': ttc_facture, 'tauxtva': taux_tva, 'tva': mt_tva, 'prorata': element.prorata })

                })
            })

            // ------------------formating facture client doit----------------
            Object.keys(Tva.data.factures.doit_client).forEach(key => {
                const element = Tva.data.factures.doit_client[key];

                const ttc = element.ttc.objttc
                const ht_tva = ttc.ht_tva
                const tva = ttc.tva
                Object.keys(ht_tva).forEach(taux => {
                    const ht = ht_tva[taux]
                    const mt_tva = tva[taux]
                    const taux_tva = taux.substring(3)
                    const ttc_facture = Math.round((Number(mt_tva) + Number(ht)) * 100) / 100

                    arrDoitCl.push({ 'num': element.facture.num_format, 'totalht': ht, 'totalttc': ttc_facture, 'tauxtva': taux_tva, 'tva': mt_tva, 'prorata': element.prorata })

                })

            })
            // ------------------formating facture client avoir----------------
            Object.keys(Tva.data.factures.avoir_cl).forEach(key => {
                const element = Tva.data.factures.avoir_cl[key];

                const ttc = element.ttc.objttc
                const ht_tva = ttc.ht_tva
                const tva = ttc.tva
                Object.keys(ht_tva).forEach(taux => {
                    const ht = ht_tva[taux]
                    const mt_tva = tva[taux]
                    const taux_tva = taux.substring(3)
                    const ttc_facture = Math.round((Number(mt_tva) + Number(ht)) * 100) / 100
                    arrAvoirCl.push({ 'num': element.facture.num_format, 'totalht': ht, 'totalttc': ttc_facture, 'tauxtva': taux_tva, 'tva': mt_tva, 'prorata': element.prorata })

                })
            })

            return { Tva: Tva.data, journal: journal.data, tvaVente: tvaVente, tvaCo: tvaCo, tvaImmo: tvaImmo, facturesDoitFr: arrDoitFr, facturesDoitCl: arrDoitCl, facturesAvoirFr: arrAvoirFr, facturesAvoirCl: arrAvoirCl }
        } catch (error) {
            throw new Error('Failed to fetch Tvas')
        }
    }
)
export const createTva = createAsyncThunk(
    'tvas/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik

        try {
            const response = await BaseService.post('/XML/compta.tva.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/comptabilite/Tva')

            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const payTva = createAsyncThunk('tvas/pay',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        data.compte = data.compte.split('_')[1]
        try {
            const response = await BaseService.post('/XML/tr.ba.send/tva', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    })

export const deleteTva = createAsyncThunk(
    'tva/delete',
    async (payload) => {
        const { data, setTvaAdded } = payload

        try {
            const response = await BaseService.post('/XML/compta.tva.send/delete', data)
            if (response.status === 200) {
                popNotification(creation?.success)
                setTvaAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.tvas.data
export const selectIsLoading = (state) => state.tvas.isLoading
export const selectError = (state) => state.tvas.error
export const selectIsDeleted = (state) => state.tvas.isDeleted
export const selectCurrentPage = (state) => state.tvas.currentPage
export const resetdetailsTvas = tvasSlice.actions.resetdetailsTvas
export const startLoading = tvasSlice.actions.startLoading
export const stopLoading = tvasSlice.actions.stopLoading
export default tvasSlice.reducer
